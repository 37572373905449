import { WibeProduct } from '@hultafors/wibe/types';

import { createProductSlug } from './create-product-slug';
import { routes } from './routes';

/**
 * @desc Creates to product detail page from product data. The only value that is actually needed on detail page to feth data is productId everything else if just for show
 * @param {string} name Name of product
 * @param {string} parentCategory
 * @param {string} category
 * @param {string} productId
 * @param {string} sku
 * @returns
 */
export const createDetailPageUrl = ({
  name,
  parentCategory,
  category,
  productId,
  sku,
}: WibeProduct): string => {
  return `/${[
    routes.CATEGORY,
    parentCategory || 'parent',
    category || 'category',
    createProductSlug(name || 'name', sku),
    productId,
  ].join('/')}`.toLowerCase();
};
