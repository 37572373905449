import slugify from 'slugify';

import {
  CategoryPageLinkFragment,
  InformationPageLinkFragment,
  isCategoryPageLinkFragment,
  isInformationPageLinkFragment,
} from '@hultafors/wibe/types';

import { routes } from './routes';

type MenuItemPage = CategoryPageLinkFragment | InformationPageLinkFragment;

interface CreateMenuItemUrlInput {
  title: string;
  children: any[];
  page: MenuItemPage;
}

/**
 *
 * @param {object} parent { title:string, children:[], page: {name:string, slug:string}}
 * @param {object} child { title:string, children:[], page: {name:string, slug:string}}
 * @returns
 */
export const createMenuItemUrl = ({
  parent: parentIn,
  child,
}: {
  parent?: CreateMenuItemUrlInput;
  child?: CreateMenuItemUrlInput;
  useParentCategoryLinks?: boolean;
}): string => {
  let url = '';

  if (!parentIn) {
    return url;
  }
  const parent = parentIn;
  let parentSlug = '';

  // let modelApiKey = '';
  if (!parentIn.page) {
    // if the parent doesn't have a page, we fake the slug from the parents title.
    // Guides submenu should have parent category link so they don't have a page
    parentSlug = slugify(parent.title);
    if (
      child?.page &&
      isInformationPageLinkFragment(child.page) &&
      child?.page?.parentListPage?.slug
    ) {
      parentSlug = child.page.parentListPage.slug;
    }
  } else {
    parentSlug = parent.page.slug || '';
    // modelApiKey = parent.page._modelApiKey;
  }

  let pageRoute = routes.GUIDES;

  if (isCategoryPageLinkFragment(parent.page)) {
    pageRoute = routes.CATEGORY;
  }

  if (isInformationPageLinkFragment(parent.page)) {
    pageRoute = routes.GUIDES;
  }

  url = `/${pageRoute}/${parentSlug}`;

  if (!child || !child.page) {
    return url;
  }

  return `${url}/${child.page.slug}`;
};
