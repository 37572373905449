import { MinMax } from '@hultafors/shared/types';

import { WibeProduct } from '@hultafors/wibe/types';

export const renderPrice = (
  minValue: number,
  maxValue: number,
  currency: string,
) => {
  if (!maxValue && !minValue) {
    return '';
  } else if (maxValue === minValue) {
    return `${currency} ${Math.round(minValue)} `;
  } else if (maxValue && minValue) {
    return `${currency} ${Math.round(minValue)} – ${Math.round(maxValue)} `;
  } else {
    return `${currency} ${Math.round(minValue || maxValue)} `;
  }
};

export const getMinMaxPriceFromModels = (
  models: WibeProduct[],
  key: 'price' | 'priceWithVat',
): MinMax | null => {
  const mins = models?.filter(
    (item) => typeof item[key]?.value?.min !== 'undefined',
  );
  const maxes = models?.filter(
    (item) => typeof item[key]?.value?.min !== 'undefined',
  );
  if (!mins.length || !maxes.length) {
    return null;
  }
  return {
    max: Math.max(...(maxes.map((item) => item[key]?.value?.max || 0) || [])),
    min: Math.min(...(mins.map((item) => item[key]?.value?.min || 0) || [])),
  };
};
