import { routes } from './routes';

/**
 * @desc Creates category url, child is optional parent is not
 * @param {object} parent { name: string, slug: string}
 * @param {object} child { name: string, slug: string}
 * @returns {string} url to either parent category page or child category page
 */
export const createCategoryUrl = ({
  parentSlug,
  childSlug,
}: {
  parentSlug?: string;
  childSlug?: string;
}): string => {
  let url = `/${routes.CATEGORY}`;
  if (parentSlug) {
    url += `/${parentSlug}`;
  } else {
    // we have no parent category, abort ship link to landing page
    return '/';
  }
  if (childSlug) {
    url += `/${childSlug}`;
  }

  return url;
};
