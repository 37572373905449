export const spacing = {
  l: '48px',
  m: '24px',
  mega: '17.5rem',
  menuHeight: '56px',
  // 280px
  none: '0',

  s: '16px',

  side: '32px',

  xl: '96px',

  xs: '8px',

  xss: '12px',

  xxl: '192px',
  xxs: '4px',
} as const;

export type Spacing = keyof typeof spacing;
